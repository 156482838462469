@charset "UTF-8";
body {
  line-height: 1.41667;
  font-size: 14px;
  font-weight: 0;
  font-family: Verdana,Geneva,sans-serif,'Microsoft Yahei','微软雅黑','PingFang SC' !important;
  color: #000;
  background: #F1F1F1;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 30px; }

a, a:hover, a:focus {
  color: #000; }

.container {
  max-width: 1150px; }

:focus {
  outline: none !important;
  text-decoration: none !important; }

.child-option {
  display: block;
  padding-left: 40px !important; }

.img-placeholder {
  display: none; }

.dropdown-menu {
  border: 0px;
  top: 34px; }

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 9px solid #fff;
  left: 6px;
  top: 27px; }

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 9px solid #fff;
  left: 6px;
  top: 27px; }

.global_notify {
  border: none;
  border-radius: 8px;
  color: #fff;
  background-color: #00C6C6;
  box-shadow: none;
  padding: 2% 5%;
  margin-bottom: 20px; }

.global_notify a {
  color: #fff;
  text-decoration: underline; }

.global_notify a:hover {
  color: #fff;
  text-decoration: underline; }

.browser-v {
  margin: 10% auto !important; }

.bootstrap-select .btn {
  color: #333 !important; }

.bootstrap-select .btn.disabled {
  color: #999 !important; }

.dropdown-menu > li > a {
  font-size: 18px; }

.wechat_icon {
  width: 100px;
  margin: 0 auto;
  line-height: 30px; }

.wechat_icon i {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("/assets/img/wechat_focus.png");
  background-size: 100%;
  float: left; }

.checkbox input[type="checkbox"], .radio input[type="radio"] {
  width: 18px;
  height: 18px;
  margin-top: 0; }

.radio input[type="radio"]:focus + label::before, .radio input[type="checkbox"]:focus + label::before, .checkbox input[type="checkbox"]:focus + label::before, .checkbox input[type="radio"]:focus + label::before {
  outline: none; }

.radio label, .checkbox label {
  padding-left: 0;
  line-height: 1; }

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
  margin-left: 8px; }

.bootstrap-select .btn {
  padding: 0 25px 0 0;
  border: none;
  background: none; }

.bootstrap-select .btn:active, .bootstrap-select .btn:focus, .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
  box-shadow: none; }

.bootstrap-select .bs-caret {
  position: absolute;
  width: 18px;
  min-height: 18px;
  background: url(/assets/img/home/caret-default.png) right center no-repeat;
  background-size: 18px;
  right: 0px; }

.bootstrap-select .bs-caret span {
  display: none !important; }

.bootstrap-select.open > .btn-default.dropdown-toggle:hover, .bootstrap-select.open > .btn-default.dropdown-toggle:focus, .bootstrap-select .btn-default:active, .bootstrap-select .btn-default.active, .bootstrap-select.open > .btn-default.dropdown-toggle {
  border: none;
  background: none; }

.bootstrap-select.btn-group.open .dropdown-toggle {
  box-shadow: none; }

.bootstrap-select .btn-default.disabled:hover, .bootstrap-select .btn-default.disabled:focus {
  background: none; }

.main-container, .tt-w800 {
  max-width: 940px;
  margin: auto; }

.main-container, .post-detail {
  word-wrap: break-word; }

.container-lg .tt-box {
  padding-bottom: 50px; }

.container-lg .no-bottom {
  padding-bottom: 4px; }

.alert ul li {
  font-size: 14px; }

textarea.autosize, .self-description textarea {
  box-sizing: border-box;
  min-height: 50px; }

.tt-red, .tt-red:hover {
  color: #f00 !important; }

.tt-warning, .tt-warning:hover {
  color: #8a6d3b; }

.tt-danger, .tt-danger:hover {
  color: #a94442; }

.tt-tac {
  text-align: center; }

.tt-tal {
  text-align: left; }

.tt-tar {
  text-align: right; }

.tt-btn {
  display: inline-block;
  min-width: 120px;
  padding: 5px 10px;
  font-size: 18px;
  text-align: center;
  line-height: 1;
  color: #fff;
  background: #000;
  border: none;
  border-radius: 5px; }

.tt-box {
  margin: auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px; }

.tt-box.lg, .container-lg {
  max-width: 1150px;
  margin: auto; }

.tt-reminder.md {
  margin: auto; }

.tt-box-tags.md {
  margin: 22px auto; }

.tt-box.md, .tt-reminder.md, .tt-box-tags {
  max-width: 1150px; }

.tt-box.border {
  border: 1px solid #979797;
  background: #F1F1F1; }

.tt-box.buttom-border {
  border-bottom: 1px solid #979797;
  background: #F1F1F1;
  border-radius: 0px;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.tt-box.btns {
  padding-left: 0;
  background: none; }

.tt-box.btns.tt-tac .tt-btn {
  margin-left: 10px;
  margin-right: 10px; }

.tt-box.opt {
  padding-top: 10px;
  padding-bottom: 10px; }

.tt-box.opt a {
  margin: 0 7px; }

.tt-avatar img, img.tt-avatar {
  width: 36px; }

.big-avatar img {
  width: 80px; }

.tt-btn:hover, .tt-btn:focus {
  text-decoration: none;
  color: #fff; }

.tt-btn.disabled, .tt-btn.disabled:hover {
  color: #333;
  background: #e0e0e0; }

.tt-reminder {
  color: #9B9B9B; }

.index-reminder {
  color: #9B9B9B;
  font-size: 12px;
  margin-bottom: 20px; }

.tt-nowrap {
  white-space: nowrap; }

.tt-form label {
  font-weight: normal; }

.tt-form .tt-text {
  border: none;
  padding: 5px 10px; }

.tt-form.white .tt-text {
  border: solid 1px #9B9B9B; }

.tt-select {
  display: inline-block; }

.tt-select select::-ms-expand {
  display: none; }

.tt-select select {
  padding-right: 23px;
  border: none;
  appearance: none;
  background: url(/assets/img/home/caret-default.png) right center no-repeat;
  background-size: 18px;
  background: none\9\0; }

.tt-select + .tt-select {
  margin-left: 2em; }

.tt-mt30 {
  margin-top: 30px; }

.tt-mt20 {
  margin-top: 20px;
  clear: both; }

.tt-mt15 {
  margin-top: 15px; }

.tt-mt10 {
  margin-top: 10px; }

.tt-ml10 {
  margin-left: 10px; }

.tt-mr10 {
  margin-right: 10px; }

.tt-mb10 {
  margin-bottom: 10px; }

.tt-w940 {
  margin: auto; }

.tt-danger {
  color: #a94442; }

.tt-success {
  color: #3c763d; }

.tt-warning {
  color: #8a6d3b; }

.tt-gray {
  color: #9B9B9B; }

.tt-gray-link {
  color: #9B9B9B; }

.tt-gray-link a, a:hover, a:focus {
  color: #9B9B9B; }

.tt-center-container {
  text-align: center; }

.tt-center-container > * {
  display: inline-block;
  text-align: left; }

.tt-fz12 {
  font-size: 12px; }

.tt-fz18, .tt-fz18 .btn {
  font-size: 18px; }

.tt-w36 {
  width: 36px;
  height: 36px; }

.tt-c333 {
  color: #333; }

div.tt-opt {
  padding-top: 10px;
  padding-bottom: 10px !important; }

div.tt-opt a, div.tt-opt span {
  margin: 0 7px; }

div.tt-opt p {
  margin-top: 4px;
  margin-bottom: 0; }

.no-deco:hover {
  text-decoration: none; }

.btns.three .tt-btn {
  min-width: 90px;
  margin-left: 5px;
  margin-right: 5px; }

.tt-btn.active {
  background: #f00; }

.tt-require-sign {
  color: red;
  line-height: 13px;
  margin-top: 10px;
  float: left;
  margin-right: 5px; }

.tt-teal-tag-span {
  background: #00C6C6;
  padding: 2px 5px;
  color: #fff;
  font-size: 12px;
  border-radius: 3px; }

.fixed-top {
  position: fixed !important;
  width: 100% !important;
  top: 0;
  left: 0; }

.input-mini {
  width: 60px !important; }

.input-small {
  width: 90px !important; }

.input-medium {
  width: 150px !important; }

.input-large {
  width: 210px !important; }

.wechat-green {
  background: #28AC2D !important; }

[v-cloak] {
  display: none; }

[data-redirect] a {
  color: #fff;
  text-decoration: underline; }

[role=button] {
  text-decoration: none !important; }

p[data-redirect], div[data-redirect] {
  margin-top: 20px; }

.center-container.modal {
  padding: 0 !important;
  text-align: center; }

.center-container.modal:before {
  content: '';
  display: inline-block;
  margin-right: -5px;
  height: 100%;
  vertical-align: middle; }

.center-container.modal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle; }

.modal-dialog {
  margin: 27px auto; }

.help-block {
  font-size: 12px;
  white-space: normal;
  color: #a94442; }

.help-block label {
  visibility: hidden; }

.tt-btn.disabled, .tt-btn[disabled], fieldset[disabled] .tt-btn {
  cursor: not-allowed; }

.checkbox label::after {
  top: 0;
  margin-left: -21px; }

.diamond {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  background: url(/assets/img/home/icon-diamond.png) no-repeat;
  background-size: contain; }

.diamond.user {
  width: 12px !important;
  margin-top: 3px !important;
  margin-right: 3px !important; }

.social-share {
  -webkit-text-stroke-width: 0px !important; }

div.shareto .icon-shareto {
  margin-right: 10px;
  cursor: pointer;
  color: #000; }

div.shareto .icon-shareto:hover {
  background: none; }

div.shareto .social-share-icon, div.shareto .icon-shareto::before {
  display: none; }

.tt-share-area .disk {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  background: #9B9B9B;
  border-radius: 50%;
  color: #fff;
  text-decoration: none; }

.tt-share-area .disk .title {
  margin-top: 29px;
  margin-bottom: 0;
  font-size: 30px; }

.tt-share-area .disk .status {
  display: none;
  position: absolute;
  right: 0;
  right: 0;
  top: 0;
  width: 26px;
  height: 26px;
  font-size: 10px;
  line-height: 26px;
  text-align: center;
  color: #9B9B9B;
  background: url(/assets/img/home/icon-tip.png) no-repeat;
  background-size: contain; }

.pagination > li > a, .pagination > li > span {
  color: #00C6C6; }

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  background-color: #00C6C6;
  border-color: #00C6C6; }

.tt-prompt .modal-content, .tt-prompt .modal-header, .tt-prompt .modal-footer {
  border: none; }

.tt-prompt .modal-body {
  padding: 0 10px 15px;
  line-height: 1.57; }

.tt-prompt .modal-header, .tt-prompt .modal-footer {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center; }

.tt-prompt .modal-content, .tt-prompt .wrap {
  border: none;
  border-radius: 8px;
  color: #fff;
  background-color: #00C6C6;
  box-shadow: none;
  margin-left: -27px;
  margin-right: 27px; }

.tt-prompt .modal-footer {
  padding-bottom: 30px; }

.tt-prompt .modal-footer .tt-btn {
  margin: 0 10px; }

.tt-prompt .close {
  color: #fff;
  opacity: .62; }

.tt-prompt .header {
  padding: 15px 10px;
  text-align: center; }

.tt-prompt .content {
  padding: 0 10px 30px;
  line-height: 1.57; }

.tt-prompt .content .btns {
  margin-top: 20px; }

.tt-prompt .content .btns .tt-btn {
  margin-right: 20px; }

.tt-prompt .textarea {
  width: 100%;
  height: 120px;
  padding: 10px;
  border: none;
  line-height: 1.714;
  color: #000;
  resize: none; }

.tt-prompt .inputs {
  color: #000; }

.tt-prompt .inputs .radio label::before, .tt-prompt .inputs .checkbox label::before {
  top: -2px; }

.tt-prompt .inputs .checkbox label::after {
  top: 0; }

.tt-prompt .inputs .radio label::after {
  top: 1px; }

.tt-prompt div[class*="col-"] {
  padding: 0; }

.tt-prompt .radio-inline + .radio-inline, .tt-prompt .checkbox-inline + .checkbox-inline {
  margin-left: 0; }

.tt-prompt .radio.radio-inline, .tt-prompt .checkbox.checkbox-inline {
  margin-right: 3px;
  margin-bottom: 10px; }

.tt-prompt .inputs .text-right {
  text-align: center;
  line-height: 1;
  white-space: nowrap; }

.top-nav {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  background: #000; }

.top-nav a {
  color: #fff; }

.top-nav .logo {
  width: 90px;
  height: 50px;
  margin: 0;
  background: url(/assets/img/logo.svg?3) center no-repeat;
  background-size: 90px 18px; }

.top-nav .logo a {
  display: block;
  height: 100%;
  text-indent: -9999px; }

.top-nav .arrow {
  width: 38px;
  height: 50px;
  background: url(/assets/img/home/mobile-home-default.png) center no-repeat;
  background-size: 18px; }

.top-nav .arrow.active {
  background-image: url(/assets/img/home/mobile-home-click.png); }

.top-nav .list {
  margin: 0;
  margin-left: 10px;
  padding: 0;
  list-style: none; }

.top-nav .list > li {
  float: left;
  margin-left: 10px; }

.top-nav .list > li > a {
  display: block;
  font-size: 18px;
  line-height: 50px; }

.top-nav .list > li > a[role=button] {
  line-height: 26px; }

.top-nav .nav-right {
  margin-left: 0; }

.top-nav .nav-right .notification-count {
  vertical-align: 0px;
  padding: 1px 3px;
  background: #f4645f;
  font-size: 5px;
  color: #fff;
  border-radius: 10px;
  float: left;
  margin: 14px 0 0 17px; }

.top-nav .nav-right > li:first-child {
  margin-left: 0; }

.top-nav .tt-avatar img {
  margin-top: 7px;
  vertical-align: top; }

.top-nav .nav-right .dropdown-menu {
  left: auto;
  right: 0;
  min-width: 120px;
  top: 50px; }

.top-nav .nav-main .dropdown-menu {
  left: auto;
  min-width: 120px;
  top: 50px; }

.top-nav .nav-main .dropdown-menu, .top-nav .nav-right .dropdown-menu {
  background: #000; }

.top-nav .nav-main .dropdown-menu a, .top-nav .nav-right .dropdown-menu a {
  color: #fff; }

.top-nav .nav-main .dropdown-menu a:hover, .top-nav .nav-right .dropdown-menu a:hover {
  background: #4A4A4A; }

.top-nav .notification a, .top-nav .search a {
  width: 28px;
  height: 28px;
  margin-top: 11px;
  border: 1px solid #fff;
  border-radius: 5px;
  text-indent: -9999px; }

.top-nav .notification a {
  background: url(/assets/img/home/icon-mail.png) center no-repeat;
  background-size: 16px; }

.top-nav .notification a.note {
  background: url(/assets/img/home/icon-mail-note.png) center no-repeat;
  background-size: 16px; }

.top-nav .search a {
  background: url(/assets/img/home/icon-search.png) center no-repeat;
  background-size: 16px; }

.top-nav .post {
  width: 60px;
  height: 28px;
  margin-top: 11px;
  border: 1px solid #fff;
  border-radius: 5px;
  text-align: center; }

.mobile-menu li {
  list-style: none;
  text-align: left;
  padding-left: 28px;
  line-height: 36px;
  font-size: 16px; }

.footer {
  display: none;
  width: 100%;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #9B9B9B; }

.footer, .footer .links a {
  color: #fff; }

.footer .links a {
  margin-right: 10px; }

.footer .sm .copy {
  margin-right: 5px; }

.footer .xs .copy, .footer .xs .links {
  display: block;
  text-align: center; }

.footer .xs .copy {
  margin-top: 5px; }

.record_info {
  margin-top: 30px;
  color: #999;
  text-align: center; }

.record_info a {
  text-decoration: underline;
  color: #999; }

.user-show-header {
  text-align: center; }

.user-show-header p {
  margin-bottom: 6px; }

.user-show-header .name {
  font-size: 18px;
  word-wrap: break-word; }

.user-show-header .address {
  color: #9B9B9B; }

.user-show-header .address span {
  margin-left: 3px;
  margin-right: 3px; }

.user-show-header .nav {
  margin-top: 16px;
  margin-bottom: 20px; }

.user-show-header .nav a {
  margin: 0 4px;
  font-size: 18px; }

.user-show-header a:hover, .user-show-header a.active {
  text-decoration: none; }

.user-recharge-tips {
  max-width: 940px;
  margin: 20px;
  padding-left: 16px; }

.user-show-main, .user-edit-main, .user-edit-aside {
  margin-top: 20px; }

.user-show-main p {
  margin-bottom: 16px; }

.user-show-main p:last-child {
  margin-bottom: 0; }

.user-edit-title {
  margin-bottom: -4px;
  font-size: 18px;
  text-align: center; }

.user-edit-form input[type=text], .user-edit-form .city .tt-select {
  width: 120px; }

.user-edit-form .un input {
  margin-right: 10px; }

.user-edit-form .form-group {
  margin-bottom: 12px; }

.user-edit-form .form-group:last-child {
  margin-bottom: 0; }

.user-edit-form .form-group span {
  display: inline-block;
  line-height: 30px; }

.user-edit-form .tt-label {
  min-width: 4em;
  text-align: right;
  white-space: nowrap; }

.user-edit-form .distpicker {
  display: inline-block; }

.user-edit-form select {
  height: 30px; }

.user-edit-form .tt-avatar {
  margin-right: 10px; }

.user-edit-form .email .btns {
  margin-top: 12px; }

.user-edit-form .tt-box.errors {
  padding-top: 0;
  padding-bottom: 0;
  color: #a94442;
  background: none; }

.user-edit-form .tt-box.errors li {
  margin-top: 10px; }

.user-edit-form .radio.radio-inline, .user-edit-form .checkbox.checkbox-inline {
  margin-left: 10px; }

.user-edit-form.enterprise .user-edit-aside input[type=text], .user-edit-form .reason input, .user-edit-form .deadline input {
  width: 377px; }

.user-edit-form .bootstrap-select .bs-caret {
  height: 30px; }

.user-edit-form.update-password .tt-text {
  width: 168px; }

.user-edit-form.update-password .tt-label {
  width: 6em; }

.user_list object .img {
  max-height: 115px !important; }

.user_list object .img img {
  margin-top: -16%; }

.user_list_post_cover {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  min-height: 115px; }

.post_estimate {
  font-size: 12px;
  display: block;
  background: #00C6C6;
  position: absolute;
  color: #fff;
  padding: 0 3px;
  top: 20px;
  right: 10px; }

.user_list .tt-user .info.type {
  float: none;
  font-size: 12px;
  width: 46px;
  position: absolute;
  right: 16px;
  top: 17px;
  box-shadow: -8px 0px 0px #fff; }

.user-notifications-title {
  margin-top: 20px;
  font-size: 18px; }

.user-notifications-main {
  margin-top: 10px; }

.user-notifications .tt-reminder {
  margin: auto;
  margin-top: 20px; }

.user-notifications .no-notifications {
  padding-top: 50px;
  padding-bottom: 50px; }

.user-notifications-main ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  font-size: 12px; }

.user-notifications-main li .time {
  float: left;
  width: 12%;
  margin-right: 3px;
  white-space: nowrap;
  color: #9B9B9B;
  line-height: 25px; }

.user-notifications-main li p {
  float: left;
  width: 87%; }

.user-notifications-main li .category {
  color: #f00;
  float: left;
  margin-left: 12px;
  line-height: 25px; }

.user-notifications-main li .text {
  float: left;
  width: 77%;
  line-height: 25px; }

.user-notifications-main li:last-child p {
  margin-bottom: 0; }

.register-header span {
  display: inline-block;
  width: 117px;
  height: 40px;
  margin-right: -3px;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: #9B9B9B;
  background: url(/assets/img/home/label-default.png) no-repeat;
  background-size: contain; }

.register-header span.active {
  color: #fff;
  background-image: url(/assets/img/home/label-active.png); }

.register-choose-container {
  font-size: 12px; }

.register-choose-title {
  margin-top: 30px;
  font-size: 18px; }

.register-choose-container .tt-block {
  margin-top: 30px; }

.register-choose-container .tt-block .tt-reminder {
  margin-top: 20px; }

.register-ic-container .reminder {
  line-height: 1.714; }

.register-ic-container .no-code {
  margin-top: 35px; }

.register-ic-container .no-code p:first-child {
  margin-bottom: 20px; }

.register-ic-container .tt-text {
  width: 202px;
  margin-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px; }

.register-ic-container .tt-form .tt-block, .register-ic-container .form-group {
  margin-bottom: 10px; }

.register-ic-container .tt-form .confirm-btn {
  margin-top: 20px; }

.register-ic-container .tt-form span.tt-reminder {
  margin-left: 10px;
  font-size: 12px; }

.register-ic-container .pay-online {
  font-size: 18px; }

.register-ic-container .tt-prompt.tt-w940 {
  margin-bottom: 20px; }

.register-ic-container .tt-prompt .radio:first-child {
  margin-left: -5px; }

.register-ic-container .help-block label {
  margin-right: 5px;
  font-size: 14px; }

.register-ic-container .radio + .help-block label {
  margin-right: 10px; }

.register-ic-container .radio.radio-inline, .register-ic-container .checkbox.checkbox-inline {
  margin-left: 10px; }

.user-edit-form .distpicker .radio.radio-inline:first-of-type, .user-edit-form .distpicker .checkbox.checkbox-inline:first-of-type {
  margin-left: 0; }

.register-personal-container .tt-reminder, .register-company-container .tt-reminder {
  margin-left: 10px;
  font-size: 12px; }

.register-personal-container .tt-form label, .register-company-container .tt-form label {
  width: 5em;
  margin-right: 10px;
  text-align: right; }

register-ic-container
.register-personal-container .form-group, .register-company-container .form-group {
  margin-bottom: 10px; }

.register-personal-container .tt-text, .register-personal-container .email, .register-company-container .tt-text, .register-company-container .email {
  display: inline-block;
  width: 202px; }

.register-personal-container label, .register-company-container label {
  font-size: 18px; }

.register-company-container {
  font-size: 12px; }

.register-company-title {
  margin-top: 10px;
  font-size: 18px;
  text-align: center; }

.register-company-container .reminder {
  margin-top: 20px; }

.register-company-container .reminder p {
  margin-bottom: 15px; }

.register-company-container .tt-form {
  margin-top: 20px; }

.login-container {
  font-size: 12px; }

.login-container .tt-form {
  display: inline-block;
  margin-top: 30px;
  font-size: 14px;
  text-align: left; }

.login-container .tt-form label {
  width: 3em;
  margin-right: 10px;
  font-size: 18px;
  text-align: right; }

.login-container .tt-text {
  width: 220px; }

.login-container .code .tt-text {
  width: 110px; }

.login-container .code img {
  width: 100px;
  margin-left: 10px; }

.login-container .btns .tt-btn {
  margin-left: 10px;
  margin-right: 10px; }

.login-container .tt-reminder {
  margin-top: 30px; }

.password-reset-container .tt-form label {
  width: 9em; }

.recharges-container .amount .tt-text {
  width: 80px; }

.post-create-container .main-form .form-group {
  margin-left: 0;
  margin-right: 0; }

.post-create-container .main-form [class*="col-sm"] {
  padding: 0; }

.post-create-container .main-form .tt-text {
  width: 100%; }

.post-create-container .main-form .score .tt-text {
  width: 80px; }

.post-create-container .main-form label[class*="col-sm"] {
  margin-top: 5px; }

.post-create-container .tt-form.main-form .tt-reminder {
  font-size: 12px; }

.post-create-container .main-form .tt-box {
  padding-left: 0;
  padding-right: 0; }

.post-create-container .main-form .tt-box > div.tt-reminder {
  padding-top: 10px;
  border-top: solid 1px #979797; }

.post-create-container .main-form .wrapper {
  padding-left: 20px;
  padding-right: 20px; }

.post-create-container .editor {
  margin-top: 20px; }

.price {
  display: inline-block;
  padding: 0px 5px;
  background-color: #999999;
  color: #ffffff;
  border-radius: 2px;
  margin-left: 5px; }

.recommand {
  display: inline-block;
  padding: 0px 5px;
  background-color: #999999;
  color: #ffffff;
  border-radius: 2px;
  margin-left: 5px; }

.comment_child_list {
  background: #f1f1f1;
  padding-top: 6px;
  padding-bottom: 10px;
  margin-top: 10px;
  border-radius: 8px; }

.comment-child-item {
  border-bottom: 1px solid #ddd;
  margin: 15px 25px; }

.comment-child-item:last-child {
  border-bottom: 0px; }

.mce-tinymce {
  border: none !important;
  border-top: solid 1px rgba(0, 0, 0, 0.2) !important; }

.mce-tinymce .mce-panel {
  background: #f1f1f1; }

.mce-tinymce .mce-i-fa {
  font-family: FontAwesome; }

.mce-toolbar-grp > div {
  max-width: 940px;
  margin: auto; }

.tt-alert {
  position: fixed;
  left: 50%;
  top: -4px;
  z-index: 10001;
  transform: translateX(-50%); }

.tt-alert .alert {
  max-width: 600px;
  margin: 0 auto; }

.editor-modal .modal-dialog {
  max-width: 640px;
  min-width: 290px; }

.editor-modal .bootstrap-filestyle {
  display: inline-block; }

.editor-modal .msg {
  margin-top: 5px;
  font-size: 12px;
  color: #9B9B9B; }

.editor-modal .template-upload {
  margin-top: 10px; }

.editor-modal .template-upload > div {
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px; }

.editor-modal .template-upload .content {
  position: relative;
  padding-top: 100%;
  background-position: center;
  background-size: cover; }

.editor-modal .template-upload .content i {
  position: absolute;
  z-index: 9;
  right: 5px;
  top: 5px;
  padding: 6px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer; }

.editor-modal .template-upload .content .tt-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  height: 8px; }

.editor-modal .template-upload .content .tt-progress span {
  display: block;
  width: 0;
  height: 100%;
  background: #1483D6 url(/assets/img/upload-progress.png) repeat-x;
  background-size: 8px 8px;
  transition: width .2s linear;
  animation: progressmove 2s linear infinite; }

.editor-modal .template-upload .content.done .tt-progress, .editor-modal .template-upload .content.fail .tt-progress {
  display: none; }

.editor-modal .template-upload .content span.done {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  background: url(/assets/img/upload-success.png) no-repeat;
  background-size: contain; }

.editor-modal .template-upload .content.done span.done {
  display: block; }

.editor-modal .template-upload .content .error {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; }

.editor-modal .template-upload .content .error .msg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 5px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

.editor-modal .template-upload .content .error .icon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  background: url(/assets/img/upload-error.png) no-repeat;
  background-size: contain; }

.editor-modal .template-upload .content.fail .error {
  display: block; }

.editor-modal .template-upload .name {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 32px;
  margin-top: -16px;
  padding: 0 5px;
  text-align: center;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
  background: rgba(0, 0, 0, 0.5); }

.editor-modal .template-upload .size {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
  background: rgba(0, 0, 0, 0.5); }

.masonry-category .bootstrap-select {
  padding: 0 18px 0 0; }

.masonry-category .bootstrap-select .btn {
  font-size: 18px; }

.masonry-category .bs-caret {
  top: 3px; }

.masonry-category ul.nav {
  margin: 0 auto;
  width: 382px; }

.masonry-category ul.nav a {
  font-size: 18px;
  background: #000;
  color: #fff;
  padding: 3px 40px; }

.masonry-category ul.nav a:hover, .masonry-category ul.nav li.active a {
  background: #00C6C6; }

.masonry-category ul.user li a:hover, .masonry-category ul.user li.active a {
  background: red !important; }

.masonry-category ul.user li a:hover, .masonry-category ul.user li.activeblue a {
  background: #00C6C6 !important; }

.masonry-category ul.nav li {
  margin-right: 15px; }

.masonry-category ul.nav li:last-child {
  margin-right: 0px; }

.masonry-cards {
  margin-top: 20px; }

.masonry-cards .card {
  margin-bottom: 10px;
  padding: 0; }

.masonry-cards .wrap {
  display: block;
  margin-left: 5px;
  margin-right: 5px;
  background: #fff;
  border-radius: 8px;
  word-break: break-all; }

.masonry-cards a.wrap:hover {
  text-decoration: none;
  box-shadow: 0 0 5px #cecece; }

.masonry-cards a.wrap > div {
  padding: 10px; }

.masonry-cards img {
  width: 100%; }

.masonry-cards .title, .masonry-cards .meta {
  padding: 10px; }

.masonry-cards .meta {
  font-size: 12px;
  color: #9B9B9B; }

.masonry-cards .meta a {
  color: #9B9B9B; }

.masonry-cards .wrap .img {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px; }

.masonry-cards .wrap.has-jobs .img {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px; }

.masonry-cards .title + .meta, .masonry-cards .meta + .meta, .masonry-cards .job .meta {
  padding-top: 0; }

.masonry-cards .list-jobs {
  background: #00C6C6;
  color: #fff !important;
  padding-top: 15px !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.masonry-cards .list-jobs a {
  color: #fff; }

.masonry-cards .abs-left {
  position: absolute;
  left: 10px; }

.masonry-cards .diamond {
  margin-top: 3px; }

.masonry-cards .company .info {
  color: #000;
  font-size: 14px;
  display: block;
  text-overflow: ellipsis;
  float: right;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  width: 78%;
  line-height: 34px; }

.masonry-cards .company .meta {
  color: #9B9B9B;
  font-size: 14px;
  height: 46px;
  overflow: hidden;
  padding: 10px 0px;
  margin-bottom: 10px; }

.masonry-cards span.tag {
  font-size: 12px;
  background: red;
  color: #fff;
  padding: 0px 3px;
  border-radius: 6px;
  margin-left: 10px; }

.more-cards {
  clear: both; }

.more-cards .more {
  margin-top: 20px; }

.more-cards > div {
  display: none; }

.nodata {
  display: none;
  padding: 50px 0;
  background: #fff;
  border-radius: 8px;
  font-size: 18px; }

.masonry-container .nodata {
  display: block; }

.masonry-search .inline-block {
  display: inline-block;
  width: 324px;
  text-align: left;
  margin-top: 30px;
  border: 2px solid #00c6c6;
  border-radius: 7px; }

.masonry-search .tt-text {
  float: left;
  width: 292px;
  height: 28px;
  padding: 0 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  background: #fff; }

.masonry-search .btn-search {
  float: left;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border: none;
  border-radius: 0 5px 5px 0;
  color: #fff;
  background: #CCC url(/assets/img/home/icon-search.png) center no-repeat;
  background-size: 17px; }

.masonry-cards .detail {
  font-size: 12px;
  color: #9B9B9B; }

.masonry-cards .wrap .user-detail {
  margin-top: -8px;
  padding-top: 0; }

.masonry-cards .user-detail span {
  float: left;
  margin-top: 8px;
  width: 33.33%; }

.masonry-cards .user-detail-zero-width span {
  float: left;
  padding-top: 0px;
  margin-top: 0px; }

.masonry-category .bootstrap-select.fit-width.type .filter-option {
  text-align: center; }

.tt-user img {
  width: 36px;
  border-radius: 50%; }

.tt-user .info {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  color: #9B9B9B; }

.tt-user .info.type {
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
  padding-left: 3px;
  padding-right: 3px;
  color: #fff;
  border-radius: 3px;
  background: #9B9B9B;
  line-height: 1.41667; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.infinite {
  animation-iteration-count: infinite; }

.rotate {
  animation-name: rotate;
  animation-timing-function: linear; }

.post-content * {
  max-width: 100% !important; }

.post-show-container .post-title {
  font-size: 18px;
  line-height: 1.416;
  font-weight: 400; }

.post-show-container .post-content {
  line-height: 1.714; }

.post-show-container .post-content img, .post-show-container .post-content video {
  max-width: 100%; }

.post-show-container .post-content video {
  max-height: 530px;
  background: #000; }

.post-show-container .post-attachment a {
  padding-left: 22px;
  font-size: 18px;
  color: #333;
  background: url(/assets/img/home/icon-download.png) 0 center no-repeat;
  background-size: 18px; }

.post-show-container .post-opt {
  padding-top: 10px;
  padding-bottom: 10px; }

.post-show-container .post-opt a {
  margin: 0 7px; }

.post-show-container .praise {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  background: #9B9B9B;
  border-radius: 50%;
  color: #fff;
  text-decoration: none; }

.post-show-container .praise .title {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 30px; }

.post-show-container .praise .praised {
  display: none;
  position: absolute;
  right: 0;
  right: 0;
  top: 0;
  width: 26px;
  height: 26px;
  font-size: 10px;
  line-height: 26px;
  text-align: center;
  color: #9B9B9B;
  background: url(/assets/img/home/icon-tip.png) no-repeat;
  background-size: contain; }

.post-comments {
  margin-top: 20px; }

.post-comments .meta {
  font-size: 12px;
  line-height: 36px;
  color: #9B9B9B; }

.post-comments .meta a {
  color: #9B9B9B; }

.post-comments .meta a, .post-comments .meta span {
  margin-left: 3px;
  margin-right: 3px; }

.post-comments .content {
  margin-top: 10px;
  line-height: 1.714; }

.post-comments .item {
  margin-bottom: 2px; }

#post-comment-form textarea {
  display: block;
  width: 100%;
  max-width: 940px;
  height: 143px;
  margin: 10px auto;
  padding: 10px;
  border: none;
  background: #fff;
  resize: none; }

.post-comments .tt-reminder {
  font-size: 12px; }

.resumes-index-main {
  line-height: 1.571; }

.resumes-index-main .block {
  margin-top: 15px; }

.resumes-index-main .title {
  margin-bottom: 10px; }

.resumes-index-main p {
  margin-bottom: 0; }

.resumes-edit-container .tt-box {
  padding-left: 0;
  padding-right: 0; }

.resumes-edit-container .tt-label {
  margin-right: .5em;
  color: #9B9B9B; }

.resumes-edit-container .block {
  margin-top: 20px; }

.resumes-edit-container .block p {
  line-height: 1.714; }

.resumes-edit-box .tt-text {
  width: 194px; }

.resumes-edit-box .checkbox label::after {
  margin-left: -21px; }

.resumes-edit-box .checkbox label::before {
  top: -1px; }

.resumes-edit-box .checkbox input {
  width: 18px;
  height: 18px;
  margin-top: 0; }

.resumes-edit-box .checkbox.checkbox-inline {
  margin-left: 0;
  margin-right: 8px;
  margin-top: 10px; }

.resumes-edit-box .self-description .bg {
  padding: 10px 0;
  border-top: solid 1px #979797;
  border-bottom: solid 1px #979797;
  line-height: 1.714;
  background: #fff; }

.resumes-edit-box .self-description .bg textarea, .resumes-edit-box .self-description .bg p {
  width: 100%;
  border: none;
  line-height: 1.714;
  vertical-align: middle;
  resize: none; }

.resumes-edit-permission .filter-option {
  margin-top: -2px; }

.resumes-edit-permission .bootstrap-select .bs-caret {
  margin-top: 2px; }

.resumes-edit-container .step2 .hyphen {
  margin-left: 22px;
  margin-right: 4px; }

.resumes-edit-container .step2 .tt-text {
  width: 210px; }

.resumes-edit-container .step2 .form-label {
  margin-right: .5em; }

.resumes-edit-container .step2 .list p .del {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  vertical-align: middle;
  background: url(/assets/img/home/icon-del.png) no-repeat;
  background-size: 18px; }

.resumes-edit-container .step2 .tt-form, .resumes-edit-container .step2 .add {
  margin-left: 28px; }

.resumes-edit-container .resumes-preview {
  float: left;
  width: 27%; }

.resumes-edit-container .resumes-preview img {
  width: 100%; }

.resumes-edit-container .resumes-desc {
  float: right;
  width: 68%; }

.resumes-edit-container .resumes-desc p {
  margin-bottom: 20px;
  line-height: 1.714; }

.resumes-edit-container .step3 .tt-btn {
  margin: 0 10px; }

.unread {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background: #CE0B24;
  font-weight: bold;
  padding: 0 3px;
  border-radius: 3px; }

.update {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background: #58E2C2;
  font-weight: bold;
  padding: 0 3px;
  border-radius: 3px; }

.keep {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background: #9B9B9B;
  font-weight: bold;
  padding: 0 3px;
  border-radius: 3px; }

.agreed {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background: #58E2C2;
  font-weight: bold;
  padding: 0 3px;
  border-radius: 3px; }

.jobs-show-container .block {
  margin-top: 15px;
  line-height: 1.571; }

.jobs-show-container .tt-label {
  margin-right: .5em;
  color: #9B9B9B; }

.jobs-show-intro img {
  max-width: 100%; }

.jobs-show-intro .header img {
  max-width: 100%;
  border-radius: 50%; }

.jobs-show-intro .detail {
  line-height: 1.571; }

.jobs-show-intro .detail p {
  margin-top: 17px; }

.jobs-show-container .masonry-cards {
  margin-top: 30px; }

.jobs-show-container .masonry-cards .wrap {
  margin-left: 10px;
  margin-right: 10px; }

.jobs-show-container .masonry-cards .card {
  margin-bottom: 20px; }

.companies-introduction-main {
  line-height: 1.571; }

.companies-introduction-main p {
  margin-bottom: 20px; }

.companies-introduction-main p:last-child {
  margin-bottom: 0; }

.companies-introduction-main img {
  max-width: 100%; }

.business .checkbox-left, .business .checkbox-body {
  display: table-cell;
  vertical-align: top;
  min-width: 80px; }

.business .checkbox-left, .business-title {
  font-size: 14px;
  color: #9B9B9B; }

.business .checkbox-body {
  color: #4A4A4A; }

.business .checkbox-body .checkbox {
  padding-top: 0px;
  margin-left: 0px;
  padding-right: 0px;
  text-align: left;
  margin-bottom: 5px; }

.subject-author {
  width: 158px;
  display: block;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left; }

@keyframes rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes progressmove {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 17px 0; } }

@media (min-width: 800px) {
  .group-btns {
    text-align: center;
    padding-left: 40px; }
  .group-btns .bottom-btn {
    display: inline-block;
    margin-right: 40px; } }

@media (min-width: 1200px) {
  .modal-dialog {
    width: 940px; }
  .tt-prompt .modal-body {
    padding: 0 70px 15px; }
  .tt-prompt .content {
    padding: 0 70px 30px; }
  .user-edit-form.enterprise .un input[type=text] {
    width: 139px; }
  .register-choose-container .tt-prompt .modal-body {
    line-height: 1.833; }
  .post-create-container .header, .post-create-container .tt-select .btn {
    font-size: 18px; }
  .post-create-container .header .bs-caret {
    height: 25px; }
  .mce-toolbar-grp .mce-widget.mce-first {
    margin-left: -8px; }
  .tt-prompt .inputs .text-right {
    text-align: left; } }

@media (min-width: 768px) {
  .top-nav .nav-main {
    display: block !important; }
  .tt-radio input {
    margin: 0 5px; }
  .user-edit-aside input[type=text], .user-edit-form .city .tt-select {
    width: 189px; }
  .user-edit-form.update-password .tt-text {
    width: 349px; }
  .tt-prompt .radio.radio-inline, .tt-prompt .checkbox.checkbox-inline {
    margin-right: 10px; }
  .tt-w940 .masonry-cards .card {
    margin-bottom: 20px; }
  .tt-w940 .masonry-cards .wrap {
    margin-left: 10px;
    margin-right: 10px; }
  .resumes-edit-box input[name="incentives"] {
    width: 366px; } }

@media (max-width: 1200px) {
  .user-notifications-main li .time, .user-notifications-main li p {
    width: 100%; }
  .resumes-edit-box.tt-box .tt-w800 {
    padding-left: 10px;
    padding-right: 10px; }
  .user_list object .img img {
    margin-top: -8%; }
  .masonry-cards .user-detail span {
    width: auto;
    margin-left: 5px; } }

@media (max-width: 768px) {
  .user_list object .img img {
    margin-top: -25%; } }

@media (max-width: 767px) {
  .wechat-green {
    margin-top: 20px; }
  .modal-dialog {
    margin: 27px; }
  .top-nav {
    margin-bottom: 20px;
    background: #222; }
  .top-nav .container {
    padding-left: 10px;
    padding-right: 10px; }
  .top-nav .nav-main {
    display: none;
    position: absolute;
    z-index: 9;
    left: 0;
    top: 50px;
    width: 100%;
    margin-left: 0;
    text-align: center;
    background: #000;
    padding: 10px 0px 10px;
    background: #222;
    border-bottom: 1px solid #101010;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    padding: 0px; }
  .top-nav .nav-main > li {
    float: none;
    margin: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .top-nav .nav-main > li > a {
    color: #ffffff;
    font-size: 20px;
    line-height: 38px;
    text-align: left;
    padding: 3px 0px 3px 35px;
    margin: 0px;
    font-weight: bold; }
  .top-nav .nav-main > li > a:hover {
    text-decoration: none;
    color: #fff;
    background-color: #080808; }
  .top-nav .nav-main .dropdown-menu {
    float: none;
    width: 100%;
    position: relative;
    top: 0;
    padding: 10px 0px;
    margin-top: 1px; }
  .top-nav .nav-main .dropdown-menu a {
    padding-left: 65px; }
  .tt-prompt .modal-content, .tt-prompt .wrap {
    margin-left: 0;
    margin-right: 0; }
  .user-show-header .address span {
    display: block;
    margin: 3px 0; }
  .user-edit-form .reason input, .user-edit-form .deadline input {
    width: 175px; }
  .user-edit-form.enterprise .user-edit-aside input[type=text] {
    width: 200px; }
  .tt-box.btns.tt-tac {
    padding-left: 0;
    padding-right: 0; }
  .tt-box.btns.tt-tac .tt-btn {
    margin-left: 5px;
    margin-right: 5px; }
  .user-edit-form .radio.radio-inline, .user-edit-form .checkbox.checkbox-inline {
    margin-left: 0; }
  .register-choose-container .tt-block {
    margin-top: 20px; }
  .register-choose-container .tt-block .tt-reminder {
    margin-top: 10px; }
  .register-header span {
    width: 94px;
    height: 32px;
    line-height: 32px; }
  .register-personal-container .tt-form label, .register-company-container .tt-form label {
    font-size: 12px; }
  .register-personal-container .tt-text, .register-company-container .tt-text {
    width: 210px; }
  .register-personal-container input[name=invitation_code], .register-company-container input[name=invitation_code] {
    width: 110px; }
  .register-company-container .reminder {
    margin-top: 0; }
  .register-ic-container .tt-form span.tt-reminder {
    display: block;
    margin-top: 10px;
    margin-left: 18px; }
  .login-container .tt-form label {
    font-size: 16px; }
  .login-container .tt-text {
    width: 200px; }
  .login-container .code .tt-text {
    width: 100px; }
  .login-container .code img {
    width: 90px; }
  .login-container .btns .tt-btn {
    margin-left: 5px;
    margin-right: 5px; }
  .post-create-container .main-form label[class*="col-sm"] {
    margin-top: 0;
    margin-bottom: 10px;
    margin-top: 5px; }
  .post-create-container .main-form .wrapper {
    padding-left: 10px;
    padding-right: 10px; }
  .masonry-cards {
    margin-top: 20px; }
  .btns.three .tt-btn {
    min-width: 80px; }
  .btns.three .tt-btn.m-line-one {
    margin-bottom: 10px; }
  .business .checkbox-left {
    display: block;
    margin-bottom: 10px; }
  div.business {
    width: 320px;
    margin: 0 auto; }
  .business .row.checkbox-body {
    margin-left: 0px; }
  .masonry-category ul.nav {
    width: 320px; }
  .masonry-category ul.nav li {
    width: 30%;
    margin-right: 14px; }
  .masonry-category ul.nav a {
    padding: 3px 30px; }
  .masonry-category div.filters ul.filter_list {
    margin-left: 40px; }
  .tt-prompt .wrap {
    margin-left: 0px;
    margin-right: 0px; }
  a.add-tags {
    float: left;
    clear: right; }
  .user_list_post_cover {
    padding: 10px !important;
    margin-right: 0px;
    min-height: 68px; }
  .user_list_post_cover:nth-child(3), .user_list_post_cover:nth-child(5) {
    padding-left: 5px !important; }
  .user_list_post_cover:nth-child(2), .user_list_post_cover:nth-child(4) {
    padding-right: 5px !important; }
  .post_estimate {
    right: 10px; }
  .user_list object .img img {
    margin-top: -14%; }
  .masonry-cards .user-detail span {
    width: auto;
    margin-left: 5px; } }

@media (max-width: 374px) {
  .tt-btn {
    min-width: 100px; }
  .container {
    padding-left: 15px;
    padding-right: 15px; }
  .modal-dialog {
    margin: 15px; }
  .tt-box {
    padding: 20px 10px; }
  .tt-box.btns {
    padding: 20px; }
  .user-edit-form .un {
    white-space: nowrap; }
  .user-edit-form .un input {
    margin-right: 3px; }
  .user-edit-form .reason input, .user-edit-form .deadline input {
    width: 160px; }
  .user-edit-form .radio, .user-edit-form .checkbox {
    margin-left: 0;
    padding-left: 18px; }
  .user-edit-form .radio label, .user-edit-form .checkbox label {
    margin-left: -3px; }
  .user-edit-form .radio.radio-inline, .user-edit-form .checkbox.checkbox-inline {
    margin-left: 3px; }
  .user-edit-form .user-edit-aside input[type=text], .user-edit-form .user-edit-aside .city .tt-select {
    width: 110px; }
  .register-ic-container .tt-text {
    width: 190px; }
  .register-personal-container .tt-text, .register-company-container .tt-text {
    width: 200px; }
  .register-personal-container input[name=invitation_code], .register-company-container input[name=invitation_code] {
    width: 110px; }
  .register-personal-container .tt-form label, .register-company-container .tt-form label {
    margin-right: 5px; }
  .register-personal-container .tt-reminder, .register-company-container .tt-reminder {
    margin-left: 5px; }
  .register-ic-container .tt-prompt .radio {
    margin-left: 0; }
  .password-reset-container .tt-text {
    width: 180px; }
  .password-reset-container .code .tt-text {
    width: 80px; }
  .masonry-category .bootstrap-select:first-child {
    margin-left: -10px; }
  .masonry-category .bootstrap-select:last-child {
    margin-right: 14px;
    margin-left: 0; }
  .btns.three .tt-btn {
    min-width: 80px; } }

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: black;
  border-color: black;
  cursor: default; }

.create-works-reminder {
  margin-top: 20px;
  padding: 0;
  background: none;
  text-align: left;
  font-size: 12px;
  color: #9B9B9B; }

.create-works-button {
  padding: 0;
  background: none; }

.max-height {
  max-height: 590px;
  overflow: hidden; }

.page.title {
  max-width: 940px;
  margin: auto; }

.row.introduction {
  line-height: 40px; }

ul.tags {
  margin: 0px; }

ul.tags {
  color: #9B9B9B;
  font-size: 12px; }

.tt-box-tags label, a.add-tags {
  line-height: 23px; }

.tt-box-tags label {
  color: #9B9B9B; }

a.add-tags {
  color: #f00;
  margin-left: 15px; }

ul.tags li {
  list-style: none;
  float: left;
  line-height: 23px; }

ul.tags li a, ul.years li a {
  padding: 2px 5px;
  border: 1px solid #979797;
  background: #fff;
  color: #000;
  margin-right: 5px;
  border-radius: 5px; }

.edit-tag-box {
  margin-top: 15px; }

.edit-tag-box label {
  color: #fff; }

.edit-tag-box ul.tags li a, .search-tags ul.tags li a, .search-tags ul.years li a {
  background: none;
  border: 0px; }

.search-tags .show-tag-list li a {
  border: 1px solid #979797 !important;
  background: #fff !important;
  color: #000;
  border-radius: .25em;
  padding: .2em .6em .3em;
  display: block; }

.edit-tag-box ul.tags li a:hover,
.edit-tag-box ul.tags li.active a,
.search-tags ul.tags li.active a,
.search-tags ul.tags li a:hover,
.search-tags ul.years li.active a,
.search-tags ul.years li a:hover {
  text-decoration: none;
  background: #fff;
  border-radius: 5px; }

.search-tags {
  background: none; }

.search-tags .tips {
  font-size: 12px;
  color: #9B9B9B;
  margin-bottom: 10px; }

.search-tags ul {
  margin: 0px; }

.search-tags ul li {
  font-size: 12px;
  float: left;
  list-style: none; }

.search-tags div label {
  color: #9B9B9B;
  margin-right: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px; }

.mce-toolbar-grp.fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1); }

.notification_icon {
  border-radius: 50%;
  background: red;
  width: 6px;
  display: block;
  position: relative;
  margin-top: -35px;
  height: 6px;
  margin-left: 4px; }

.filters label[for='title'] {
  color: #ccc;
  margin-right: 10px;
  font-weight: normal;
  line-height: 23px; }

.filters ul li {
  list-style: none;
  margin-right: 5px;
  line-height: 23px; }

.tt-user .info.type.send_message:hover {
  background: #000;
  text-decoration: none; }

.bluec6 {
  background-color: #00C6C6 !important;
  color: white; }

.like_button {
  background-color: #FFF;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 5px; }
