$icon-font-path: "/assets/fonts/bootstrap/";
$fa-font-path: "/assets/fonts/font-awesome/";
$grid-gutter-width: 54px;
$container-large-desktop: 1278px;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/sweetalert/dist/sweetalert";
@import "node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.scss";
@import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min";
@import "node_modules/bootstrap-select/dist/css/bootstrap-select.min";

.fa-btn {
    margin-right: 6px;
}

.table-vcenter td {
   vertical-align: middle !important;
}

.tab-search {
    padding-bottom: 5px;
    margin-bottom: 10px;
}
